import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Icon } from "@iconify/react";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import { useCookies } from 'react-cookie';
import ModalWindow from "../Components/ModalWindow";
// Add Components
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { getCartList, getOrderConfrim, postAddress, postAddressDelete, postAddressList, postDefaultAddress, postUpdateAddress, getCityList, getPinCodeList, productAvailability, getOrderSettings, postCartDelete, getCartCount, getCartPrice } from "../Services/serviceApi";
//import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";
import Availability from "../Components/availabilitycheck";
import { useParams } from "react-router-dom";


const Checkout = () => {
  const { id } = useParams()
  const [lgShow, setLgShow] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [addresses, setAddresses] = useState([] || '');
  const [defaultAddress, setDefaultAddress] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addressId, setAddressId] = useState('');
  const [modalData, setModal] = useState({ show: false, type: '', title: '', message: '' })
  const [errors, setErrors] = useState({});
  const Navigate = useNavigate()
  const [cookies] = useCookies(['SESSIONINFO'], ['LOGGEDINFO']);
  const userId = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? cookies.LOGGEDINFO : cookies.SESSIONINFO;
  const userType = (cookies.LOGGEDINFO != '' && cookies.LOGGEDINFO != undefined) ? '' : 'guest';
  const [cityList, setCityList] = useState([]);
  const [pinCodeList, setPinCodeList] = useState([]);
  const [minimumAmount, setMinimumAmount] = useState('');
  const [maximumAmount, setMaximumAmount] = useState('');
  const [openModal, setOpenModal] = useState({ show: false, type: '', title: '', message: '' })
  const [cartId, setCartId] = useState(0);
  const [priceChanging, setPriceChanging] = useState('no');
  const [orderId, setOrderId] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [loading, setLoading] = useState(false);

  const handleCloseModal = () => {
    setLgShow(false);
    setSelectedAddress(null)
    setFormData(initialFormData);
    setErrors(initialErrors);
  };

  const initialErrors = {
    custName: '',
    custMobile: '',
    custEmail: ''
  };

  const [formData, setFormData] = useState({
    userId: '',
    custName: '',
    custMobile: '',
    custEmail: '',
    custAddress: '',
    custArea: '',
    custLandmark: '',
    custState: 'Tamil Nadu',
    custCity: '',
    custPincode: ''
  });
  const { custName, custMobile, custEmail, custAddress, custArea, custLandmark, custState, custCity, custPincode } = formData;

  const initialFormData = {
    userId: userId,
    custName: '',
    custMobile: '',
    custEmail: '',
    custAddress: '',
    custArea: '',
    custLandmark: '',
    custState: 'Tamil Nadu',
    custCity: '',
    custPincode: ''
  };

  const fetchData = () => {
    getCityList().then(response => {
      setCityList(response);
    })
  }

  useEffect(() => {
    fetchData();
    orderSettings();
  }, [])

  const validateForm = () => {
    const errors = {};
    if (!custName.trim()) {
      errors.custName = "Name is required";
    }
    else if (custName.length < 3) {
      errors.custName = "Invalid customer name";
    }
    if (!custMobile.trim()) {
      errors.custMobile = "Mobile number is required";
    } else if (!validateMobile(custMobile)) {
      errors.custMobile = "Invalid mobileii number";
    }
    if (custEmail.trim() && !validateEmail(custEmail)) {
      errors.custEmail = "Invalid Email address";
    } /* else if () {
      errors.custEmail = "Invalid email address";
    } */
    if (!custAddress.trim()) {
      errors.custAddress = "Address is required";
    }
    if (!custArea.trim()) {
      errors.custArea = "Area is required";
    }
    /*  if (!custLandmark.trim()) {
       errors.custLandmark = "Landmark is required";
     } */
    if (!custCity.trim()) {
      errors.custCity = "City is required";
    }
    if (custState != undefined && !custState.trim()) {
      errors.custState = "State is required";
    }
    if (!custPincode.trim()) {
      errors.custPincode = "Pincode is required";
    }
    else if (!validatePincode(custPincode)) {
      errors.custPincode = "Invalid Pincode";
    }
    return errors;
  };

  const validateMobile = (mobile) => {
    return /^[6789][0-9]{9}$/i.test(mobile);
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9.]+@[a-zA-Z]+\.[a-zA-Z]{2,4}$/;
    return regex.test(email);
  };

  const validatePincode = (pincode) => {
    return /^\d{6}$/i.test(pincode);
  };

  const addAddress = () => {
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      postAddress(userId, custName, custMobile, custEmail, custAddress, custArea, custLandmark, custState, custCity, custPincode).then(response => {
        setLgShow(false);
        addressListing()
        setErrors(initialErrors);
        setFormData({});
      })
    }
    else {
      setErrors(errors)
    }
  }

  const addressListing = () => {
    postAddressList(userId).then(response => {
      setAddresses(response.data.addresslist)
    })
  }

  const updateAddress = () => {
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      postUpdateAddress(userId, custName, custMobile, custEmail, custAddress, custArea, custLandmark, custState, custCity, custPincode, selectedAddress.address_id).then(response => {
        setLgShow(false);
        addressListing();
        setErrors(initialErrors);
      })
    }
    else {
      setErrors(errors)
    }
  }

  const handleEditAddress = (address) => {
    setSelectedAddress(address);
    setLgShow(true);
  };

  const handleDeleteAddress = (addressId) => {
    setAddressId(addressId)
    setModal({ show: true, type: 'confirmation', title: 'Delete Address Confirmation', message: 'Are you sure want to delete Address ?' });
  }

  const handleDefaultAddress = (addressId) => {
    postDefaultAddress(addressId, userId).then(response => {
      setDefaultAddress(addressId)
      addressListing();
      // localStorage.setItem('default_address', addressId);
      // setShippingId(addressId)
    })
  }

  const calculateSubtotal = () => {
    return cartItems.reduce((acc, item) => {
      return acc + (item.offer_price !== null ? item.offer_price * item.quantity : item.price * item.quantity);
    }, 0);
  };

  const calculateGrandTotal = () => {
    const subtotal = calculateSubtotal();
    const tax = 0;
    return subtotal + tax;
  };

  const getCartItems = () => {
    getCartList(userId, userType).then(response => {
      if (response.data.status == 1 && response.data.cart_list.length > 0) {
        setCartItems(response.data.cart_list)
      }
      else {
        Navigate('/cart')
      }
    })
    return [cartItems];
  }

  const onCloseModel = (type) => {
    if (type == 1) {
      if (addressId != '') {
        postAddressDelete(userId, addressId).then(response => {
          addressListing()
        })
      }
      else if (priceChanging === 'yes') {
        Navigate('/cart');
      }
      else {
        getCartItems()
      }
    }
  };

  /*  const applyBackdropStyle = () => {
     const backdrop = document.getElementsByClassName('razorpay-container');
     if (backdrop) {
       backdrop.style.backgroundColor = '#fff';
     }
   }; */

  const orderConfrim = (data) => {
    setLoading(true)
    if (addresses === undefined) {
      setModal({ show: true, type: 'success', title: 'Add Address', message: 'Please add your shipping address' });
    }
    else if (!addresses.some(address => address.default_address === '1')) {
      setModal({ show: true, type: 'success', title: 'Choose address', message: 'Please Choose a Delivery Address' });
    }
    else {
      setLoading(true)
      const address = addresses.find(address => address.default_address === '1')
      getOrderConfrim(userId, address.address_id, data)
      .then(response => {
        let orderId = response.data.invoice_id
        setOrderId(response.data.invoice_id)
        // if (response.data.status == 200) {
        setTimeout(() => {
          setLoading(false)
          Navigate(`/order-success/${btoa(response.data.invoice_id)}`);
        }, 2000);
        // }
      })
      .catch(error=>{
        setLoading(false)
      })
    }
  }

  const orderSettings = () => {
    getOrderSettings()
      .then(response => {
        setMinimumAmount(response[0].minimum_purchase)
        setMaximumAmount(response[0].maximum_purchase)
      })
  }

  const getcartCounts = () => {
    getCartCount(userId, userType).then(response => {
      //setCartCount(response)
    })
  }

  const onClosePopup = (type) => {
    if (type === 1) {
      if (cartId.length > 0) {
        cartId.map((product) => {
          product.map((data) => {
            postCartDelete(data.cart_id, userId, userType)
              .then(response => {
                getCartItems()
                getcartCounts()
                handleSubmit()
              })
          })

        })
      }
      else if (cartId != 0) {
        postCartDelete(cartId, userId, userType)
          .then(response => {
            getCartItems()
            getcartCounts()
            handleSubmit()
          })
      }
      else if (priceChanging === 'yes') {
        Navigate('/cart')
        handleSubmit()
      }
      else {
        getCartPrice(userId).then(response => {
          getCartItems()
          getcartCounts()
        })
      }
    }
  }

  const handlePaymentMethodChange = (method) => {
    setSelectedOption(method);
  };

  const handleSubmit = () => {

    productAvailability(userId, userType).then(response => {
      /* if (cartItems.length > response.product_availability.length) {
        setModal({ show: true, type: 'success', title: 'Product Out Of Stock or not available', message: 'One of the selected product is now out of stock or not available. It has been removed from your cart.' });
        return;
      } */
      if (addresses === undefined) {
        setModal({ show: true, type: 'success', title: 'Add Address', message: 'Please add your shipping address' });
        return;
      }
      else if (!addresses.some(address => address.default_address === '1')) {
        setModal({ show: true, type: 'success', title: 'Choose address', message: 'Please Choose a Delivery Address' });
        return;
      }
      else if (!selectedOption) {
        setModal({ show: true, type: 'success', title: 'Select Payment Method', message: 'Please select a payment method to proceed.' });
        return;
      }

      let combinedMessage = [];
      let title = [];
      let cartid = [];
      setCartId(cartid)
      if (response.outOfStock.length > 0) {
        cartid.push(response.outOfStock)
        combinedMessage.push(response.outOfStock);
        title.push('This product is currently out of stock and cannot be added to your cart.')
      }
      if (response.variantChange.length > 0) {
        cartid.push(response.variantChange)
        combinedMessage.push(response.variantChange);
        title.push('This product is currently changed a variant and cannot be added to your cart.')
      }
      if (response.unitChange.length > 0) {
        cartid.push(response.unitChange)
        combinedMessage.push(response.unitChange);
        title.push('This product is currently changed a unit and cannot be added to your cart.')
      }
      if (response.inActiveProduct.length > 0) {
        cartid.push(response.inActiveProduct)
        combinedMessage.push(response.inActiveProduct);
        title.push('This product is currently unavailable and cannot be added to your cart.')
      }
      if (response.inActiveCatProduct.length > 0) {
        cartid.push(response.inActiveCatProduct)
        combinedMessage.push(response.inActiveCatProduct);
        title.push('This category is currently unavailable and cannot be added to your cart.')
      }
      if (combinedMessage.length !== 0) {
        setOpenModal({ show: true, type: 'success', title: title, message: combinedMessage });
        return;
      }
      else if (response.productPriceChange.length > 0) {
        let message = [];
        let title = [];
        setPriceChanging(response.productPriceChange);
        message.push(response.productPriceChange)
        title.push('This product is currently changed price and updated to your cart.')
        setOpenModal({ show: true, type: 'success', title: title, message: message });
        return;
      }
      else if (response.totalPrice < minimumAmount) {
        setPriceChanging('yes');
        setModal({ show: true, type: 'success', title: `Minimum ₹${minimumAmount} Required for Checkout`, message: `please ensure your cart total exceeds ₹${minimumAmount} to proceed to checkout. Thank you!` });
        return;
      }
      else if (response.totalPrice > maximumAmount) {
        setPriceChanging('yes');
        setModal({ show: true, type: 'success', title: `Maximum amount exceeds`, message: `please ensure your cart total does not exceeds ₹${maximumAmount} to proceed to checkout. Thank you!` });
        return;
      }
      else {
        if (selectedOption == 'Cash On Delivery') {
          orderConfrim('cod')
        } else {
          const subtotal = calculateSubtotal();
          // const tax = 10;
          const amount = subtotal;
          var options = {
            key: "rzp_test_gcQQOle0ReoOmI",
            key_secret: "vY4XUfi70qQqrsFqZc9LkvWG",
            amount: amount * 100,
            currency: "INR",
            name: "CHOTEKISAN",
            description: "testing",
            handler: function (response) {
              setLoading(false)
              if (response.razorpay_payment_id) {
                orderConfrim('online')
                // if (orderId != '') {
                // Navigate(`/order-success/${btoa(orderId)}`);
                // }
              }
            },
            prefill: {
              name: "",
              email: "",
              contact: ""
            },
            notes: {
              address: "villivakkam"
            },
            theme: {
              color: "#33cc66",
              // background:"#ffffff"
            },
            modal: {
              ondismiss: function () {
                setLoading(false); 
              }
            }
          };
          var pay = new window.Razorpay(options);
          setLoading(true)
          pay.open();
        }
      }
    })
  }

  // useEffect(() => {
  //   const storedDefaultAddress = localStorage.getItem('default_address');
  //   if (storedDefaultAddress) {
  //     setDefaultAddress(storedDefaultAddress);
  //   }
  // }, []);

  useEffect(() => {
    addressListing()
    // handleDefaultAddress()
  }, [])

  useEffect(() => {
    if (selectedAddress) {
      getPincode(selectedAddress.customer_city)
      setFormData({
        userId: userId,
        custName: selectedAddress.customer_name,
        custMobile: selectedAddress.customer_mobile,
        custEmail: selectedAddress.customer_email,
        custAddress: selectedAddress.customer_address,
        custArea: selectedAddress.customer_area,
        custLandmark: selectedAddress.customer_landmark,
        custState: selectedAddress.customer_state,
        custCity: selectedAddress.customer_city,
        custPincode: selectedAddress.customer_pincode,
      });
    }
    else {
      setFormData(initialFormData)
    }
  }, [selectedAddress, userId]);

  const getPincode = (data) => {
    getPinCodeList(data).then(response => {
      setPinCodeList(response.pincode_list)
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    const mobileRegex = /^[0-9]*$/
    let newErrors = { ...errors };
    if (name === 'custName') {
      if (!value.trim()) {
        newErrors[name] = "Name is required";
      }
      else {
        delete newErrors[name];
      }
    }
    if (name === 'custMobile') {
      if (!mobileRegex.test(value)) {
        return;
      }
      if (!value.trim()) {
        newErrors[name] = "mobile number is required";
      } else if (!validateMobile(value)) {
        newErrors[name] = "Invalid mobile number";
      } else {
        delete newErrors[name];
      }
    }
    if (name === 'custEmail') {
      if (value.trim() && !validateEmail(value)) {
        newErrors[name] = "Invalid Email address";
      }/*  else if () {
        newErrors[name] = "Invalid email address";
      } */
      else {
        delete newErrors[name];
      }
    }
    if (name === 'custPincode') {
      if (!value.trim()) {
        newErrors[name] = "Pincode is required";
      } else if (!validatePincode(value)) {
        newErrors[name] = "Invalid pincode";
      } else {
        delete newErrors[name];
      }
    }
    if (name === 'custAddress') {
      if (!value.trim()) {
        newErrors[name] = "Address is required";
      } else {
        delete newErrors[name];
      }
    }
    if (name === 'custArea') {
      if (!value.trim()) {
        newErrors[name] = "Area is required";
      } else {
        delete newErrors[name];
      }
    }
    /* if (name === 'custLandmark') {
      if (!value.trim()) {
        newErrors[name] = "Landmark is required";
      } else {
        delete newErrors[name];
      }
    } */
    if (name === 'custState') {
      if (!value.trim()) {
        newErrors[name] = "State is required";
      } else {
        delete newErrors[name];
      }
    }
    if (name === 'custCity') {
      if (!value.trim()) {
        newErrors[name] = "City is required";
      } else {
        delete newErrors[name];
      }
    }
    setErrors(newErrors);
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    getCartItems()
  }, [])

  const onStateChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    //setState(e.target.value)
  }

  const getCityLists = () => {
    const content = [];
    cityList.map((cityData, index) => {
      content.push(<option key={index} value={cityData.city_name}>{cityData.city_name}</option>)
    })
    return content;
  }

  const onCityChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    if (value != '') {
      getPinCodeList(e.target.value).then(response => {
        if (response.status == 200) {
          setPinCodeList(response.pincode_list)
        }
      })
    }
    else {
      setPinCodeList([]);
    }
  }

  const getPinCodeLists = () => {
    const content = [];
    // eslint-disable-next-line no-lone-blocks
    {
      (pinCodeList != undefined && pinCodeList.length > 0) && pinCodeList.map((pincode) => {
        content.push(<option value={pincode.pincode}>{pincode.pincode}</option>)
      })
      return content;
    }
  }

  const onPinCodeChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    //setPinCode(e.target.value)
  }

  const addNewAddress = () => {
    setSelectedAddress(null)
    setPinCodeList([]);
    setLgShow(true)
  }

  return (
    <>
    {loading != true ?
    <div>
      <Header />
      <section className="cart-section mt-space pt-space">
        <Container>
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to='/'>
                  Home
                  <span className="iconify">
                    <Icon icon="iconamoon:arrow-right-2-duotone" />
                  </span>
                </Link>
              </li>
              <li>
                <Link to='/cart'>
                  My Cart
                  <span className="iconify">
                    <Icon icon="iconamoon:arrow-right-2-duotone" />
                  </span>
                </Link>
              </li>
              <li className="active">Checkout</li>
            </ul>
          </div>

          <Row>
            <Col lg={9} md={12} xs={12}>
              <div
                className="cart-title headingbtn"
              >
                <h4>Choose Delivery Address</h4>
                <Link onClick={() => addNewAddress()}>Add New</Link>
              </div>
              <div className="cart-body cardbg">
                {addresses && addresses.length > 0 ? (
                  <Row>
                    {addresses.map((address, index) => (
                      <Col lg={6} md={6} xs={12} key={index}>
                        <div className="address-box">
                          <div className="address-header">
                            <Form.Check type="radio" aria-label="radio 1"
                              checked={address.default_address === '1'}
                              onChange={() => handleDefaultAddress(address.address_id)}
                            />
                            <h5>
                              {address.customer_name}
                              {address.default_address === '1' ? (
                                <span className="badge badge-success">Default</span>
                              ) : ('')}
                            </h5>
                          </div>
                          <p>
                            <span>Address: </span>{address.customer_address}, {address.customer_area},
                            {address.customer_city}, {address.customer_state}, {address.customer_pincode}
                          </p>
                          <p>
                            {(address.customer_landmark != '') && <span>Landmark: </span>}{address.customer_landmark}
                          </p>
                          <p>
                            <span>Contact No: </span>{address.customer_mobile}
                          </p>
                          <div className="edit-option">
                            <ul>
                              <li>
                                <Link>

                                  <span
                                    className="iconify"
                                    onClick={() => handleEditAddress(address)}
                                  >
                                    <Icon icon="eva:edit-outline" />
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link>
                                  <span
                                    className="iconify"
                                    onClick={() => handleDeleteAddress(address.address_id)}
                                  >
                                    <Icon icon="material-symbols:delete-outline" />
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                ) : (
                  <p className="empty-address">No addresses available.</p>
                )}
              </div>
              <div className="cart-title">
                <h4>Choose Payment Method</h4>
              </div>
              <div className="cart-body cardbg">
                <Row>
                  <Col lg={6} md={6} xs={12}>
                    <div className="delivery-option">
                      <div className="address-title" onClick={() => handlePaymentMethodChange('Cash On Delivery')}>
                        <Form.Check type="radio" aria-label="radio 1" checked={selectedOption === 'Cash On Delivery'}
                          onChange={() => { }} />
                        <h5>Cash On Delivery</h5>
                      </div>
                    </div>
                  </Col>

                  <Col lg={6} md={6} xs={12}>
                    <div className="delivery-option">
                      <div className="address-title" onClick={() => handlePaymentMethodChange('Credit or Debit Card')}>
                        <Form.Check type="radio" aria-label="radio 1" checked={selectedOption === 'Credit or Debit Card'}
                          onChange={() => { }} />
                        <h5>Credit or Debit Card / Net Banking</h5>
                      </div>
                    </div>
                  </Col>

                  {/* <Col lg={4} md={4} xs={12}>
                    <div className="delivery-option bottom-space">
                      <div className="address-title" onClick={() => handlePaymentMethodChange('Net Banking')}>
                        <Form.Check type="radio" aria-label="radio 1" checked={selectedOption === 'Net Banking'}
                          onChange={() => { }} />
                        <h5>Net Banking</h5>
                      </div>
                    </div>
                  </Col> */}
                </Row>
              </div>
            </Col>

            <Col lg={3} md={12} xs={12}>
              <div className="cart-title">
                <h4>Order Summary</h4>
              </div>
              <div className="cart-left">
                <div className="coupan-content">
                  <ul>
                    {cartItems.map((items, index) => (
                      // (items.stock_availability !== 0) && (
                      <li key={index}>
                        <div className="product-cart-list">
                          <span>{items.product_name} {items.variant_name} {items.nic_name} x {items.quantity}</span>
                        </div>
                        <h4>₹{parseInt(items.offer_price) !== 0 && items.offer_price !== null ? items.offer_price * items.quantity : items.price * items.quantity}</h4>
                      </li>
                    ))}

                  </ul>
                </div>
                <div className="coupan-content mt-2">
                  <ul>
                    <li>
                      <h4 className="text-bold text-left">Subtotal</h4>
                      <h4 className="text-bold">₹{calculateSubtotal()}</h4>
                    </li>
                    {/* <li>
                      <h4 className="text-bold text-left">Shipping Changes</h4>
                      <h4 className="text-bold">₹0</h4>
                    </li>
                    <li>
                      <h4 className="text-bold text-left">Tax (5%)</h4>
                      <h4 className="text-bold">₹10</h4>
                    </li>
                    <li>
                      <h4 className="text-left text-success">

                        <strong>Coupon Applied </strong>
                        <Link to="/">
                          <span className="iconify text-danger">

                            <Icon icon="material-symbols:delete-outline" />
                          </span>
                        </Link>
                      </h4>
                      <h4>

                        <strong className="text-success">-₹10</strong>
                      </h4>
                    </li> */}
                  </ul>
                </div>
                <div className="summary-total">
                  <ul>
                    <li>
                      <h4>Grand Total</h4>
                      <h4 className="text-success">₹{calculateGrandTotal()}</h4>
                    </li>
                  </ul>
                </div>
                {/* onClick={ (event) => event.preventDefault() } */}

                <div className="cart-button">
                  <Link onClick={() => { handleSubmit() }} >Complete Order</Link>
                </div>
                {/* <div className="return-button">
                  <Link to="/product-list"> <span className="iconify">
                              <Icon icon="ion:arrow-back-outline" />
                           </span>Return to Shopping</Link>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Modal
        size="xm"
        show={lgShow}
        onHide={handleCloseModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="popup-box">
          <Row>
            <Col lg={12} md={3} xs={12}>
              <div className="location-modal">
                <div className="signup-page">
                  <Modal.Header className="btn-head" closeButton>
                    <div className="header-modal">
                      <h4>{(selectedAddress == null) ? 'Add New Address' : 'Edit Address'}</h4>
                    </div>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="form-modal">
                      <Row>
                        <Col lg={6} md={12} xs={12}>
                          <div className="form-location inputbt-space">

                            <Form.Group
                              className="form-group"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="label-text">
                                Customer Name<b>*</b>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="customer Name"
                                name="custName"
                                value={formData.custName}
                                onChange={handleChange}
                                isInvalid={!!errors.custName}
                                maxLength={20}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.custName}
                              </Form.Control.Feedback>
                            </Form.Group>

                          </div>
                        </Col>
                        <Col lg={6} md={12} xs={12}>
                          <div className="form-location inputbt-space">
                            <Form.Group
                              className="form-group"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="label-text">
                                Mobile Number<b>*</b>
                              </Form.Label>
                              <Form.Control
                                type="tel"
                                placeholder="enter mobile number"
                                name="custMobile"
                                value={formData.custMobile}
                                onChange={handleChange}
                                isInvalid={!!errors.custMobile}
                                maxLength={10}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.custMobile}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </Col>

                        <Col lg={6} md={12} xs={12}>
                          <div className="form-location inputbt-space">

                            <Form.Group
                              className="form-group"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="label-text">
                                Email Address<b>*</b>
                              </Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="enter email address"
                                name="custEmail"
                                value={formData.custEmail}
                                onChange={handleChange}
                                isInvalid={!!errors.custEmail}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.custEmail}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </Col>

                        <Col lg={6} md={12} xs={12}>
                          <div className="form-location inputbt-space">

                            <Form.Group
                              className="form-group"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="label-text">
                                Address<b>*</b>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="enter address"
                                name="custAddress"
                                value={formData.custAddress}
                                onChange={handleChange}
                                isInvalid={!!errors.custAddress}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.custAddress}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </Col>

                        <Col lg={12} md={12} xs={12}>
                          <div className="form-location inputbt-space">

                            <Form.Group
                              className="form-group"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="label-text">
                                Area<b>*</b>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="enter area"
                                name="custArea"
                                value={formData.custArea}
                                onChange={handleChange}
                                isInvalid={!!errors.custArea}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.custArea}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </Col>

                        <Col lg={12} md={12} xs={12}>
                          <div className="form-location input-textarea form-group">
                            <Form.Label className="label-text">
                              Landmark
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                as="textarea"
                                aria-label="enter landmark"
                                placeholder="enter landmark"
                                name="custLandmark"
                                value={formData.custLandmark}
                                onChange={handleChange}
                              //isInvalid={!!errors.custLandmark}
                              />
                              {/* <Form.Control.Feedback type="invalid">
                                {errors.custLandmark}
                              </Form.Control.Feedback> */}
                            </InputGroup>
                          </div>
                        </Col>
                        <Col lg={12} md={12} xs={12}>
                          <div className="form-location inputbt-space">
                            <Form.Group
                              className="form-group"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="label-text">State<b>*</b></Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                defaultValue={formData.custState}
                                onChange={(e) => onStateChange(e)}
                                name='custState'
                                isInvalid={!!errors.custState}
                              //onClick={(e) => {onStateChange(e)}}
                              //defaultChecked = '1'
                              >
                                {/* <option value=  "">Select State</option> */}
                                <option value="Tamil Nadu">Tamil Nadu</option>
                                {/* <option value="lowest_price">Lowest Price</option>
                              <option value="highest_price">Highest Price</option>
                              <option value="today_special">Today's special</option> */}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                {errors.custState}
                              </Form.Control.Feedback>
                            </Form.Group>

                          </div>
                        </Col>
                        <Col lg={12} md={12} xs={12}>
                          <div className="form-location inputbt-space">

                            <Form.Group
                              className="form-group"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="label-text">City<b>*</b></Form.Label>
                              <Form.Select
                                name="custCity"
                                value={formData.custCity}
                                onChange={(e) => onCityChange(e)}
                                isInvalid={!!errors.custCity}
                              >
                                <option value=''>Select City</option>
                                {getCityLists()}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                {errors.custCity}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </Col>

                        <Col lg={12} md={12} xs={12}>
                          <div className="form-location inputbt-space">

                            <Form.Group
                              className="form-group"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="label-text">
                                Pincode<b>*</b>
                              </Form.Label>
                              <Form.Select
                                name="custPincode"
                                value={formData.custPincode}
                                onChange={(e) => onPinCodeChange(e)}
                                isInvalid={!!errors.custPincode}
                              >
                                <option value=''>Select pincode</option>
                                {getPinCodeLists()}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                {errors.custPincode}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </Col>

                        {/* <Col lg={12} md={12} xs={12}>
                          <div className="form-location inputbt-space">

                            <Form.Group
                              className="form-group"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="label-text">
                                City
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="enter city"
                                name="custCity"
                                value={formData.custCity}
                                onChange={handleChange}
                                isInvalid={!!errors.custCity}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.custCity}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </Col>
                        <Col lg={12} md={12} xs={12}>
                          <div className="form-location inputbt-space">

                            <Form.Group
                              className="form-group"
                              controlId="formBasicEmail"
                            >
                              <Form.Label className="label-text">
                                Pincode
                              </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="enter pincode"
                                name="custPincode"
                                value={formData.custPincode}
                                onChange={handleChange}
                                isInvalid={!!errors.custPincode}
                                maxLength={6}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.custPincode}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </Col> */}

                        <Col lg={12} md={12} xs={12}>
                          <div className="add-button mt-2">
                            <Button
                              variant="primary"
                              to="/Home"
                              className="btn-lg btn-cancel"
                              type="submit"
                              onClick={() => handleCloseModal()}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="primary"
                              className="btn-lg btn-submit btn-size"
                              type="submit"
                              onClick={(selectedAddress === null) ? addAddress : updateAddress}
                            >
                              {selectedAddress ? 'update' : 'submit'}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Modal.Body>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
      <Footer />
      <ModalWindow data={modalData} onCloseModel={onCloseModel} />
      <Availability data={openModal} onClosePopup={onClosePopup} />

    </div>
    : ''}
          {loading == true ? 
            <div className="loader-box">
              <div class="lds-ripple">
                <div className="loader-text">
                </div>
              </div>
              <div className="loader-text">
                <p>Processing your order...</p>
              </div>
            </div>
            : ''}
            </>
  );
};

export default Checkout;
